import * as React from "react";

import Layout from "../components/Base/Layout";
import Cart from "../components/Cart/Cart"

export default function LogIn() {
  return (
    <Layout>
        <Cart />
    </Layout>
  );
}