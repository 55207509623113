import * as React from 'react'

import { CloseButton, Flex, Link, NumberDecrementStepper, NumberIncrementStepper, NumberInput, NumberInputField, NumberInputStepper, Select, SelectProps, Text, useColorModeValue } from '@chakra-ui/react'
import { useEffect, useState } from 'react'

import { CartProduct } from '../../../types/CartProduct'
import { CartProductMeta } from './CartProductMeta'

type CartItemProps = {
  isGiftWrapping?: boolean
  name?: string
  quantity: number
  price: string
  imageUrl?: string
  productTags: any
  databaseId: any
  key: string
  onChangeQuantity: (qty: number) => void
  onClickGiftWrapping?: () => void
  onClickDelete?: (key:any) => void
  categories?: CartProduct['productCategories']

}

export const CartItem = (props: CartItemProps) => {
  
  const {
    name,
    quantity,
    imageUrl,
    price,
    key,
    productTags,
    onChangeQuantity,
    onClickDelete,
    categories,
  } = props

  var priceSub = price.slice(0, - 3);

  const bonusProductsCount = React.useMemo(()=> {
    let bonusPerTen: number
    const isDermalFillersCat = categories.nodes.some((item: any) => item.name === 'Dermal Fillers')
    const isBotulinumToxins = categories.nodes.some((item: any) => item.name === 'Botulinum Toxins')
    if(isDermalFillersCat) {
      bonusPerTen = 3
    } else if (isBotulinumToxins) {
      bonusPerTen = 2
    } else {
      return undefined
    }

    return Math.floor(quantity / 10) * bonusPerTen
  },[categories, quantity])


   const [quantity1, setSelectedOption] = useState(1)

  useEffect(() => {
    const storedSelectedOption = parseInt(sessionStorage.getItem('quantity1') || '1')
    setSelectedOption(storedSelectedOption)
  }, [])

  const handleOnChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedOption(parseInt(e.target.value))
    sessionStorage.setItem('quantity1', e.target.value)
  }
  

  return (
    <Flex direction={{ base: 'column', md: 'row' }} justify="space-between" align="center">
      <CartProductMeta
        bonusProductsCount={bonusProductsCount}
        quantity={quantity}
        name={name}
        image={imageUrl}
        productTags={productTags}
      />

      {/* Desktop */}
      <Flex width="full" justify="space-between" alignItems="center" display={{ base: 'none', md: 'flex' }}>
         <NumberInput
            min={1}
            defaultValue={quantity}
            onBlur={(e) => { onChangeQuantity(parseInt(e.currentTarget.value)) }}
            width="20%"
          >
          <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>

        <Text fontWeight="bold"> { priceSub }</Text>
        <CloseButton aria-label={`Delete ${name} from cart`} onClick={onClickDelete} />

      </Flex>

      {/* Mobile */}
      <Flex
        mt="4"
        align="center"
        width="full"
        justify="space-between"
        display={{ base: 'flex', md: 'none' }}
      >
        <Link fontSize="sm" textDecor="underline">
          Delete
        </Link>
        <Text fontWeight="bold"> { priceSub }</Text>
         <NumberInput
            min={1}
            defaultValue={quantity}
            onBlur={(e) => { onChangeQuantity(parseInt(e.currentTarget.value)) }}
            width="20%"
          >
          <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
      </Flex>
    </Flex>
  )
}